import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { cartReducer } from './reducers/cartReducers';
import { categorieListReducer, categoryCreateReducer, categoryDeleteReducer, categoryDetailsReducer, categoryUpdateReducer } from './reducers/categorieReducers';
import { notificationReducer } from './reducers/notificationReducers';
import { courseCreateReducer, courseDeleteReducer, courseDetailsReducer, courseListReducer, courseUpdateReducer } from './reducers/courseReducers';
import { orderCreateReducer, orderDeleteReducer, orderDeliverReducer, orderDetailsReducer, orderEditReducer, orderHideReducer, orderListNotPaidReducer, orderListReducer, orderMineListReducer, orderPayReducer, orderSalesInfoReducer, orderSalesProductReducer, ordersChangeToPaidReducer, ordersImportReducer, ordersPaidByDateReducer } from './reducers/orderReducers';
import { productListReducer, productDetailsReducer, productCreateReducer, productUpdateReducer, productDeleteReducer, categoryProductListReducer } from './reducers/productReducers';
import { subscriptionProductListReducer, subscriptionProductDetailsReducer, subscriptionProductCreateReducer, subscriptionProductUpdateReducer, subscriptionProductDeleteReducer } from './reducers/subscriptionProductReducers';
import { userDeleteReducer, userDetailsReducer, userForgotPasswordReducer, userListReducer, userRegisterReducer, userResetPasswordReducer, userSigninReducer, usersImportReducer, usersTeachersReducer, userUpdateProfileReducer, userUpdateReducer } from './reducers/userReducers';
import { attributeCreateReducer, attributeDeleteReducer, attributeDetailsReducer, attributeListReducer, attributeUpdateReducer } from './reducers/attributeReducers';
import { studentDeleteReducer, studentDetailsReducer, studentListReducer, studentRegisterReducer, studentsImportReducer, studentUpdateReducer } from './reducers/studentReducers';
import { salesSubscriptionProductReducer, subscriptionCancelReducer, subscriptionListReducer, subscriptionMineListReducer, subscriptionPaidReducer, subscriptionRequestCancelReducer, subscriptionsPaidByDateReducer } from './reducers/subscriptionReducers';
import { extracurricularActivityCreateReducer, extracurricularActivityDeleteReducer, extracurricularActivityDetailsReducer, extracurricularActivityListReducer, extracurricularActivitySplittedListReducer, extracurricularActivityUpdateReducer } from './reducers/extracurricularActivityReducers';
import { extracurricularActivityInscriptionCancelReducer, extracurricularActivityInscriptionDetailsReducer, extracurricularActivityInscriptionEnrollReducer, extracurricularActivityInscriptionListReducer, extracurricularActivityInscriptionManagementReducer, extracurricularActivityInscriptionMineListReducer, extracurricularActivityInscriptionPaidReducer, extracurricularActivityInscriptionRequestCancelReducer, extracurricularActivityInscriptionsPaidByDateReducer, salesExtracurricularActivityReducer } from './reducers/extracurricularActivityInscriptionReducers';
import { paymentmethodCreateReducer, paymentmethodDeleteReducer, paymentmethodDetailsReducer, paymentmethodListReducer } from './reducers/paymentMethodReducers';
import { messageReducer } from "./reducers/messageReducers";
import { stopbullyingReducer } from "./reducers/stopbullyingReducers";

const initialState = {
    userSignin: {
        userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null
    },
    cart: {
        cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
        shippingAddress: localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : {},
        paymentMethod: null
    },


};

const reducer = combineReducers({
    productList: productListReducer,
    categoryProductList: categoryProductListReducer,
    productDetails: productDetailsReducer,
    cart: cartReducer,
    userSignin: userSigninReducer,
    userRegister: userRegisterReducer,
    orderCreate: orderCreateReducer,
    orderEdit: orderEditReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderMineList: orderMineListReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    productCreate: productCreateReducer,
    productDelete: productDeleteReducer,
    productUpdate: productUpdateReducer,
    orderList: orderListReducer,
    orderDelete: orderDeleteReducer,
    orderDeliver: orderDeliverReducer,
    orderHide: orderHideReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    usersImport : usersImportReducer,
    usersTeachers : usersTeachersReducer,

    subscriptionProductList: subscriptionProductListReducer,
    subscriptionProductDetails: subscriptionProductDetailsReducer,
    subscriptionProductCreate: subscriptionProductCreateReducer,
    subscriptionProductDelete: subscriptionProductDeleteReducer,
    subscriptionProductUpdate: subscriptionProductUpdateReducer,
    
    userForgotPassword: userForgotPasswordReducer,
    userResetPassword: userResetPasswordReducer,

    studentList : studentListReducer,
    studentDetails: studentDetailsReducer,
    registerStudent: studentRegisterReducer,
    studentDelete: studentDeleteReducer,
    studentUpdate: studentUpdateReducer,

    studentsImport : studentsImportReducer,

    categorieList: categorieListReducer,
    categoryDetails: categoryDetailsReducer,
    categoryUpdate: categoryUpdateReducer,
    categoryCreate: categoryCreateReducer,
    categoryDelete: categoryDeleteReducer,

    notifications: notificationReducer,
    messageList: messageReducer,
    stopbullyingList: stopbullyingReducer,

    courseList: courseListReducer,
    courseDetails: courseDetailsReducer,
    courseUpdate: courseUpdateReducer,
    courseCreate: courseCreateReducer,
    courseDelete: courseDeleteReducer,

    orderSalesInfo: orderSalesInfoReducer,
    orderSalesProduct : orderSalesProductReducer,

    attributeList: attributeListReducer,
    attributeDetails: attributeDetailsReducer,
    attributeUpdate: attributeUpdateReducer,
    attributeCreate: attributeCreateReducer,
    attributeDelete: attributeDeleteReducer,
    ordersImport: ordersImportReducer,
    ordersPaidByDate: ordersPaidByDateReducer,

    ordersChangeToPaid: ordersChangeToPaidReducer,
    orderListNotPaid: orderListNotPaidReducer,

    subscriptionPaid: subscriptionPaidReducer,
    subscriptionMine: subscriptionMineListReducer,
    subscriptionCancel: subscriptionCancelReducer,
    subscriptionRequestCancel: subscriptionRequestCancelReducer,
    subscriptionList: subscriptionListReducer,
    salesSubscriptionProduct: salesSubscriptionProductReducer,
    subscriptionsPaidByDate: subscriptionsPaidByDateReducer,

    extracurricularActivityList: extracurricularActivityListReducer,
    extracurricularActivitySplittedList: extracurricularActivitySplittedListReducer,
    extracurricularActivityDetails: extracurricularActivityDetailsReducer,
    extracurricularActivityCreate: extracurricularActivityCreateReducer,
    extracurricularActivityDelete: extracurricularActivityDeleteReducer,
    extracurricularActivityUpdate: extracurricularActivityUpdateReducer,

    extracurricularActivityInscriptionPaid: extracurricularActivityInscriptionPaidReducer,
    extracurricularActivityInscriptionMine: extracurricularActivityInscriptionMineListReducer,
    extracurricularActivityInscriptionCancel: extracurricularActivityInscriptionCancelReducer,
    extracurricularActivityInscriptionRequestCancel: extracurricularActivityInscriptionRequestCancelReducer,
    extracurricularActivityInscriptionList: extracurricularActivityInscriptionListReducer,
    salesExtracurricularActivity: salesExtracurricularActivityReducer,
    extracurricularActivityInscriptionEnroll: extracurricularActivityInscriptionEnrollReducer,
    extracurricularActivityInscriptionManagement: extracurricularActivityInscriptionManagementReducer,
    extracurricularActivityInscriptionDetails: extracurricularActivityInscriptionDetailsReducer,
    extracurricularActivityInscriptionsPaidByDate: extracurricularActivityInscriptionsPaidByDateReducer,

    paymentmethodList: paymentmethodListReducer,
    paymentmethodDetails: paymentmethodDetailsReducer,
    paymentmethodCreate: paymentmethodCreateReducer,
    paymentmethodDelete: paymentmethodDeleteReducer
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk)));

export default store;