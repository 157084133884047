import Axios from 'axios'
import {
    NOTIFICATION_GET_ALL,
    NOTIFICATION_SEND,
    NOTIFICATION_SEND_USER,
    NOTIFICATION_SEND_CLASS,
    NOTIFICATION_READ,
    NOTIFICATION_SEND_EXTRACURRICULAR_ACTIVITY
} from '../constants/notificationConstants';

export const getNotifications = () => async (dispatch, getState) => {
    const {data} = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/notifications/`);
    dispatch({
        type: NOTIFICATION_GET_ALL,
        payload: {
            data
        }
    })
}

export const getNotificationsUser = () => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();
    try {
        const {data} = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/notifications/${userInfo._id}`);
        dispatch({
            type: NOTIFICATION_GET_ALL,
            payload: {
                data
            }
        })
    } catch (error) {

    }

}

export const sendNotificationUser = (pushNotification, notification) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/notifications', notification, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        try {
            const resp = Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/notify', pushNotification)
            console.log(resp);
        } catch (err) {

        }
        dispatch({
            type: NOTIFICATION_SEND_USER,
            payload: data
        })
        dispatch(getNotificationsUser());
    } catch (error) {}
}

export const sendNotificationParent = (student, message) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/notifications/absence', {student: student, message: message}, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        try {
            const resp = Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/notifyabsence', {student: student, message: message})
        } catch (err) {

        }
        dispatch({
            type: NOTIFICATION_SEND_USER,
            payload: data
        })
        dispatch(getNotificationsUser());
    } catch (error) {}
}

export const sendNotificationClass = (pushNotification, notification) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/notifications/class', notification, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        try {
            const resp = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/notifyclass', pushNotification)
            console.log(resp);
        } catch (err) {

        }
        dispatch({
            type: NOTIFICATION_SEND_CLASS,
            payload: data
        })
        dispatch(getNotificationsUser());
    } catch (error) {}
}

export const sendNotificationExtracurricularActivity = (pushNotification, notification) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/notifications/extracurricularactivity', notification, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        try {
            const resp = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/notifyextracurricularactivity', pushNotification)
            console.log(resp);
        } catch (err) {

        }
        dispatch({
            type: NOTIFICATION_SEND_EXTRACURRICULAR_ACTIVITY,
            payload: data
        })
        dispatch(getNotificationsUser());
    } catch (error) {}
}

export const sendNotificationAll = (pushNotification, notification) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/notifications/all', notification, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        try {
            const resp = Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/notifyall', pushNotification)
            console.log(resp);
        } catch (err) {

        }
        dispatch({
            type: NOTIFICATION_SEND,
            payload: data
        })
        dispatch(getNotificationsUser());
    } catch (error) {}
}

export const readNotification = (id) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();
    try {
        const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/notifications/${id}/read`, null, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({
            type: NOTIFICATION_READ,
            payload: {
                notification: data.notification
            }
        })
    } catch (error) {

    }

}

export const registerDevice = (token, user) => async(dispatch, getState) => {
    try {
        Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/checktoken', {
            user: user,
            token: {
                currentToken: token
            }
        });
        localStorage.setItem('fcm', token);
    } catch (error) {
        console.log(error);
    }
    
}