import { SUBSCRIPTIONPRODUCT_LIST_SUCCESS, SUBSCRIPTIONPRODUCT_LIST_FAIL, SUBSCRIPTIONPRODUCT_DETAILS_SUCCESS, SUBSCRIPTIONPRODUCT_DETAILS_FAIL, SUBSCRIPTIONPRODUCT_LIST_REQUEST, SUBSCRIPTIONPRODUCT_CREATE_REQUEST, SUBSCRIPTIONPRODUCT_CREATE_FAIL, SUBSCRIPTIONPRODUCT_CREATE_SUCCESS, SUBSCRIPTIONPRODUCT_UPDATE_REQUEST, SUBSCRIPTIONPRODUCT_UPDATE_SUCCESS, SUBSCRIPTIONPRODUCT_UPDATE_FAIL, SUBSCRIPTIONPRODUCT_DELETE_REQUEST, SUBSCRIPTIONPRODUCT_DELETE_SUCCESS, SUBSCRIPTIONPRODUCT_DELETE_FAIL, SUBSCRIPTIONPRODUCT_DETAILS_REQUEST } from '../constants/subscriptionProductConstants'
import Axios from 'axios';

export const listSubscriptionProducts = () => async(dispatch) => {
    dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptionProducts');
        dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_FAIL, payload: error.message });
    }
}

export const listCategorySubscriptionProducts = (categoryId) => async(dispatch) => {
    dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptionProducts/category/' + categoryId);
        dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_FAIL, payload: error.message });
    }
}

export const detailsSubscriptionProduct = (subscriptionProductId) => async(dispatch) => {
    dispatch({ type: SUBSCRIPTIONPRODUCT_DETAILS_REQUEST, payload: subscriptionProductId });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/subscriptionProducts/${subscriptionProductId}`);
        dispatch({ type: SUBSCRIPTIONPRODUCT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SUBSCRIPTIONPRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const getAvailableSubscriptions = () => async(dispatch) => {
    dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptionProducts/available');
        dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SUBSCRIPTIONPRODUCT_LIST_FAIL, payload: error.message });
    }
}

export const createSubscriptionProduct = (subscriptionProduct) => async(dispatch, getState) => {
    dispatch({ type: SUBSCRIPTIONPRODUCT_CREATE_REQUEST });

    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptionProducts', subscriptionProduct, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({ type: SUBSCRIPTIONPRODUCT_CREATE_SUCCESS, payload: data.subscriptionProduct })
    } catch (error) {
        dispatch({
            type: SUBSCRIPTIONPRODUCT_CREATE_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const deleteSubscriptionProduct = (subscriptionProductId) => async (dispatch, getState) => {
    dispatch({ type: SUBSCRIPTIONPRODUCT_DELETE_REQUEST, payload: subscriptionProductId });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/subscriptionProducts/${subscriptionProductId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: SUBSCRIPTIONPRODUCT_DELETE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      dispatch({ type: SUBSCRIPTIONPRODUCT_DELETE_FAIL, payload: message });
    }
};

export const updateSubscriptionProduct = (subscriptionProduct) => async(dispatch, getState) => {
    dispatch({ type: SUBSCRIPTIONPRODUCT_UPDATE_REQUEST, payload: subscriptionProduct });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/subscriptionProducts/${subscriptionProduct._id}`, subscriptionProduct, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: SUBSCRIPTIONPRODUCT_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: SUBSCRIPTIONPRODUCT_UPDATE_FAIL, error: message });
    }
};