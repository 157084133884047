import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsUser } from '../actions/notificationActions';

export const HomeScreen = () => {

  const dispatch = useDispatch();

  const userSignin = useSelector(state => state.userSignin)
  const {
    userInfo
} = userSignin;


  useEffect(() => {
    if (userInfo) {
      dispatch(getNotificationsUser());
    }
    
  }, [dispatch, userInfo])
  return (
    <>
      <style>{`body {background-image: none !important}`}</style>
      <div id="home-container">
        <div id='home-container-gradient'>
          <div className='row text-white m-0 text-center'>
            <div className='col-12 p-0 mb-3'>
              <img src='/assets/media/logos/elcarmen-white.png' alt='Logo' className='home-logo' />
            </div>
            <div className='col-12 mt-2 d-flex justify-content-center p-0'>
              <div id='home-content'>
                <h2 className='text-white'>¡HOLA!</h2>
                <p className='fs-sm-1 home-paragraph'>
                  Te damos la bienvenida a nuestra nueva app en la que podrás adquirir todos estos servicios:
                </p>
              </div>
            </div>
            <div className='col-12 mt-2 d-flex justify-content-center p-0'>
                <ul className='home-list'>
                  <li>MATERIAL ESCOLAR</li>
                  <li>
                    <span>SERVICIOS COMPLEMENTARIOS</span>
                    <ul className='sub-list'>
                      <li>Seguro Escolar</li>
                      <li>Gabinete psicopedagógico</li>
                      <li>Enfermería escolar</li>
                      <li>Monitor infantil</li>
                    </ul>
                  </li>
                  <li>ACTIVIDADES EXTRAESCOLARES Y COMPLEMENTARIAS</li>
                  <li>APORTACIONES CONGREGACIÓN CARMELITAS DESCALZOS</li>
                  <li>AMPA SAN JUAN DE LA CRUZ</li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
