import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { detailsProduct } from '../actions/productActions';
import { detailsUser } from '../actions/userActions';
import { ToolBar } from '../components/ToolBar';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { addToCart } from '../actions/cartActions';
import { listAttributes } from '../actions/attributeActions';
import { getNotificationsUser } from '../actions/notificationActions';
import Swal from 'sweetalert2';
import { detailsOrder } from '../actions/orderActions';
import { CART_EMPTY } from '../constants/cartConstants';

export const ProductScreen = (props) => {

    const params = useParams();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const productId = params.id


    const productDetails = useSelector((state) => state.productDetails);
    const {loading, product, error} = productDetails;

    const attributeList = useSelector((state) => state.attributeList);
    const {attributes} = attributeList;

    const cart = useSelector(state => state.cart);
    const {cartItems} = cart;

    const userDetails = useSelector(state => state.userDetails)

    const [qty, setQty] = useState(1)
    const [student, setStudent] = useState(null)
    const [attributeValue, setAttributeValue] = useState(null)
    const [submited, setSubmited] = useState(false)
    const [studentsOptions, setStudentsOptions] = useState([])
    const [attributesOptions, setAttributesOptions] = useState([])

    const [dates, setDates] = useState([])
    const AVAILABLE_MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    const userSignin = useSelector(state => state.userSignin)
    const {userInfo} = userSignin;

    const orderDetails = useSelector((state) => state.orderDetails);

    useEffect(() => {
        if (userInfo) {
            dispatch(listAttributes());
            dispatch(detailsProduct(productId));
            dispatch(detailsUser(userInfo._id))
            dispatch(getNotificationsUser());

        } else {
            navigate(`/`);
        }
    }, [dispatch, productId, userInfo, navigate])

    useEffect(() => {
        if (submited && JSON.parse(localStorage.getItem('cartItems'))?.length > 0 && cartItems.length === JSON.parse(localStorage.getItem('cartItems'))?.length) {
            setSubmited(false)
            navigate(`/cart`);
        }
    }, [cartItems, navigate, submited])

    useEffect(() => {
        if (product) {
            if (userDetails.user?.userChildren?.length > 0) {
                let availableAttributes = []
                let existsCourseAttributes = false
                let defaultAttrIndex = null
                let availableCourses = []
                product?.attributes.forEach((attr, ind) => {
                    if (attr.value.courses?.length > 0) {
                        existsCourseAttributes = true
                        let canUseAttr = false
                        attr.value.courses.forEach(c => {
                            if (userDetails.user?.userChildren.find(child => child.course === c)) {
                                if (!availableCourses.find(co => co === c)) {
                                    availableCourses.push(c)
                                }
                                canUseAttr = true
                            }
                        })
                        if (canUseAttr) {
                            availableAttributes.push(attr)
                            if (!defaultAttrIndex) {
                                defaultAttrIndex = ind
                            }
                        }
                    } else {
                        availableAttributes.push(attr)
                    }
                });
                product?.courses.forEach(c => {
                    if (userDetails.user?.userChildren.find(child => child.course === c._id)) {
                        if (!availableCourses.find(co => co === c._id)) {
                            availableCourses.push(c._id)
                        }
                    }
                })
                if (existsCourseAttributes) {
                    setAttributesOptions(availableAttributes)
                    setAttributeValue(defaultAttrIndex)
                } else {
                    setAttributesOptions(product.attributes)
                    setAttributeValue(0)
                }

                let children = []
                if (existsCourseAttributes || product.courses?.length > 0) {
                    userDetails.user?.userChildren.forEach(c => {
                        if (availableCourses.find(course => course === c.course)) {
                            children.push(c)
                        }
                    })
                } else {
                    if (product.students?.length > 0) {
                        userDetails.user?.userChildren.forEach(c => {
                            if (product.students.find(student => student._id === c._id)) {
                                children.push(c)
                            }
                        })
                    } else {
                        userDetails.user?.userChildren.forEach(c => {
                            children.push(c)
                        })
                    }
                }
                setStudentsOptions(children)
                
                setStudent(children[0]?._id || null)
            }
        }
    }, [userDetails, product])

    useEffect(() => {
        if (product && product.attributes?.length > 0) {
            setAttributeValue(0)
        }
    }, [product])

    useEffect(() => {
        if (attributesOptions[attributeValue]?.value.date === 'daily') {
            let datesToUse = []
            for (let i = 0; i < qty; i++) {
                datesToUse.push('')
            }
            calculateDates(datesToUse)
        }
        if (attributesOptions[attributeValue]?.value.date === 'weekly') {
            let datesToUse = []
            for (let i = 0; i < qty; i++) {
                datesToUse.push({month: `${new Date().getMonth()}/${new Date().getFullYear()}`, week: getMonthWeek(new Date())})
            }
            calculateDates(datesToUse)
        }
        if (attributesOptions[attributeValue]?.value.date === 'monthly') {
            let datesToUse = []
            for (let i = 0; i < qty; i++) {
                datesToUse.push(`${new Date().getMonth()}/${new Date().getFullYear()}`)
            }
            calculateDates(datesToUse)
        }
    }, [attributeValue, product, attributesOptions])

    // useEffect(() => {
    //     if (attributesOptions[attributeValue]?.value.date === 'daily') {
    //         let datesToUse = []
    //         for (let i = 0; i < qty; i++) {
    //             if (dates[i]) {
    //                 datesToUse.push(dates[i])
    //             } else {
    //                 datesToUse.push('')
    //             }
    //         }
    //         setDates(datesToUse)
    //     }
    //     if (attributesOptions[attributeValue]?.value.date === 'weekly') {
    //         let datesToUse = []
    //         for (let i = 0; i < qty; i++) {
    //             if (dates[i]) {
    //                 datesToUse.push(dates[i])
    //             } else {
    //                 datesToUse.push({month: `${new Date().getMonth()}/${new Date().getFullYear()}`, week: getMonthWeek(new Date())})
    //             }
    //         }
    //         setDates(datesToUse)
    //     }
    //     if (attributesOptions[attributeValue]?.value.date === 'monthly') {
    //         let datesToUse = []
    //         for (let i = 0; i < qty; i++) {
    //             if (dates[i]) {
    //                 datesToUse.push(dates[i])
    //             } else {
    //                 datesToUse.push(`${new Date().getMonth()}/${new Date().getFullYear()}`)
    //             }
    //         }
    //         setDates(datesToUse)
    //     }
    // }, [qty, attributeValue, attributesOptions])

    // useEffect(() => {
    //     console.log('calculando fechas useEffect cantidad, valor de atributo y opciones disponibles');
    //     calculateDates(dates)
    // }, [qty, attributeValue, attributesOptions])

    useEffect(() => {
        if (localStorage.getItem('orderId')) {
            dispatch(detailsOrder(localStorage.getItem('orderId')))
          }
    }, [dispatch])

    useEffect(() => {
        if (orderDetails.order?.isPaid) {
          dispatch({ type: CART_EMPTY });
          localStorage.removeItem('cartItems');
          localStorage.removeItem('orderId');
        }
    }, [orderDetails.order, dispatch])

    const addToCartHandler = () => {
        if (!userInfo) {
            navigate(`/`);
        } else {
            if (cartItems.length === 0  || cartItems.filter(i => (product.alternativePayment === true ? i.alternativePayment === product.alternativePayment : (i.alternativePayment === product.alternativePayment || !i.alternativePayment))).length > 0) {
                let attributes = null
                let hasAttributes = product.attributes?.length > 0;
                if (hasAttributes) {
                    attributes = {
                        attribute: product.attributes[attributeValue].attribute,
                        value: product.attributes[attributeValue].value.value,
                        priceImpact: product.attributes[attributeValue].priceImpact,
                    }
                }
                let datesToAdd = null
                if (dates.length > 0) {
                    datesToAdd = []
                    if (attributesOptions[attributeValue]?.value.date === 'daily') {
                        datesToAdd = dates
                    }
                    if (attributesOptions[attributeValue]?.value.date === 'weekly') {
                        dates.forEach(date => {
                            let weeks = getMonthWeeks(date.month)
                            datesToAdd.push(`${AVAILABLE_MONTHS[date.month.split('/')[0]]}/${date.month.split('/')[1]}, Semana ${date.week !== '' ? date.week : weeks[0]}`)
                        })
                    }
                    if (attributesOptions[attributeValue]?.value.date === 'monthly') {
                        dates.forEach(date => {
                            datesToAdd.push(`${AVAILABLE_MONTHS[date.split('/')[0]]}/${date.split('/')[1]}`)
                        })
                    }
                }
                dispatch(addToCart(productId, qty, student, attributes, datesToAdd))
                setSubmited(true)
            } else {
                if (product.alternativePayment) {
                    Swal.fire('Este producto pertenece a otra pasarela de pago y en su carrito tiene algún producto que no lo es', '', 'error')
                } else {
                    Swal.fire('En el carrito lleva algún producto de que pertenece a otra pasarela de pago y este no lo es', '', 'error')
                }
            }
        }
    }
    /**
     * 
     * @param {int} month the month number. January is 0
     * @returns array of number of available weeks
     */
    const getMonthWeeks = (month) => {
        const maxDay = new Date(parseInt(month.split('/')[1]), parseInt(month.split('/')[0]) + 1, 0).getDate()
        let weeks = []

        // Loop through all month days
        for (let i = 0; i < maxDay; i++) {
            const d = new Date(parseInt(month.split('/')[1]), parseInt(month.split('/')[0]), i + 1)
            const date = d.getDate()
            let day = d.getDay()
            if (day === 0) {
                day = 7
            }
            const weekOfMonth = Math.ceil((date - 1 - day) / 7)
            if (weekOfMonth + 1 > 1) {
                weeks.push(weekOfMonth + 1)
            } else {
                if (day === 1) {
                    weeks.push(weekOfMonth + 1)
                } else {
                    if (weeks.indexOf(weekOfMonth + 1) !== -1) {
                        weeks.push(weekOfMonth + 1)
                    }
                }
            }
        }
        return weeks.filter((value, index, self) => {
            return self.indexOf(value) === index
        })
    }

    /**
     * 
     * @param {date} dateToInspect the date to know the month week
     * @returns the week number of the month
     */
    const getMonthWeek = (dateToInspect) => {
        const d = new Date(dateToInspect)
        const date = d.getDate()
        let day = d.getDay()
        if (day === 0) {
            day = 7
        }
        return Math.ceil((date - day) / 7) + 1
    }
    
    const handleMonthChange = (month, index) => {
        calculateDates(dates.map((date, ind) => (
            (ind === index) ? {month: month, week: ''} : date
        )))
    }

    const handleWeekChange = (week, index) => {
        calculateDates(dates.map((date, ind) => (
            (ind === index) ? {...date, week: week} : date
        )))
    }

    const handleStudentChange = (i) => {
        let availableAttributes = []
        let existsCourseAttributes = false
        let defaultAttrIndex = null
        product?.attributes.forEach((attr, index) => {
            if (attr.value.courses?.length > 0) {
                existsCourseAttributes = true
                let canUseAttr = false
                attr.value.courses.forEach(c => {
                    if (studentsOptions.find(s => s._id === i)?.course === c) {
                        canUseAttr = true
                    }
                })
                if (canUseAttr) {
                    availableAttributes.push(attr)
                    if (!defaultAttrIndex) {
                        defaultAttrIndex = index
                    }
                }
            } else {
                availableAttributes.push(attr)
            }
        });

        if (existsCourseAttributes) {
            setAttributesOptions(availableAttributes)
            setAttributeValue(defaultAttrIndex)
        }

        setStudent(i)
    }

    const setQuantity = (quantity, maxQuantity) => {
        if(quantity > maxQuantity){
            calculateDates(dates, maxQuantity)
            setQty(maxQuantity)
        }else{
            calculateDates(dates, quantity)
            setQty(quantity)
        }
    }

    const calculateDates = (datesToCalculate, quantity) => {
        if (!quantity) {
            quantity = qty
        }
        if (attributesOptions[attributeValue]?.value.date === 'daily') {
            let datesToUse = []
            for (let i = 0; i < quantity; i++) {
                if (datesToCalculate[i]) {
                    datesToUse.push(datesToCalculate[i])
                } else {
                    datesToUse.push('')
                }
            }
            setDates(datesToUse)
        }
        if (attributesOptions[attributeValue]?.value.date === 'weekly') {
            let datesToUse = []
            for (let i = 0; i < quantity; i++) {
                if (datesToCalculate[i]) {
                    datesToUse.push(datesToCalculate[i])
                } else {
                    datesToUse.push({month: `${new Date().getMonth()}/${new Date().getFullYear()}`, week: getMonthWeek(new Date())})
                }
            }
            setDates(datesToUse)
        }
        if (attributesOptions[attributeValue]?.value.date === 'monthly') {
            let datesToUse = []
            for (let i = 0; i < quantity; i++) {
                if (datesToCalculate[i]) {
                    datesToUse.push(datesToCalculate[i])
                } else {
                    datesToUse.push(`${new Date().getMonth()}/${new Date().getFullYear()}`)
                }
            }
            setDates(datesToUse)
        }
    }

    return (
        <>
        <ToolBar titulo={product ? product.name : ''} hitos={[
            {titulo: "Inicio", enlace: "/" },
            {titulo: product ? product.name : '', enlace: "#" }]}/>
    
            
    
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
            <div className="content flex-row-fluid" id="kt_content">  
                {loading ? (<LoadingBox variant={"primary"}/>) : 
                 error ? (<AlertMessage variant={"danger"} message={error}/>) : (
                    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">    
                        <div className="col-xl-7">        
                            <div className="card card-flush h-lg-100">
                                <div className="card-body p-10">
                                    <img className="img-fluid" src={product?.image ? product.image : "/logo512.png"} alt={product?.name} />
                                </div>            
                            </div>        
                        </div>        
                        <div className="col-xl-5">
                            <div className="card h-md-100">
                                <div className="card-body d-flex flex-column">
                                    <h1>{product.name}</h1>
                                    <span style={{fontSize : `2rem`}}>{
                                            attributesOptions.length > 0
                                            ?
                                                product.price + product?.attributes[attributeValue || 0]?.priceImpact
                                            :
                                                product.price
                                        } €</span>
                                    <p>{product.description}</p>
                                    
                                    <div>
                                        {product?.countInStock ? product?.countInStock > 0 && product?.catalogMode !== true ? (
                                            <span className="badge badge-success">En Stock</span>
                                        ) : (
                                            <span className="badge badge-danger">No disponible</span>
                                        ) : product?.attributes[attributeValue || 0]?.stock > 0 && product?.catalogMode !== true ? (
                                            <span className="badge badge-success">En Stock</span>
                                        ) : (
                                            <span className="badge badge-danger">No disponible</span>
                                        )} 
                                    </div>
                                    <div>
                                        {
                                            product.attributes.length > 0 ?
                                            <div className="row mt-5">
                                                <label className="form-label fw-bold mb-2 mt-3">{attributes?.find(at => at._id === product.attributes[0].attribute).name}</label>
                                                <div className="col-12">
                                                    <select value={attributeValue || ''} onChange={e => setAttributeValue(e.target.value)} className="form-select">
                                                        {product?.attributes.map((attr, index) => (
                                                            attributesOptions.find(a => a.value.value === attr.value.value)
                                                            ?
                                                                <option key={index} value={index}>{attr.value.value}</option>
                                                            :
                                                                <Fragment key={index}></Fragment>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    <div>
                                        {
                                            userDetails?.user?.userChildren ?
                                            <div className="row mt-5">
                                                <label className="form-label fw-bold mb-2 mt-3">Estudiante</label>
                                                <div className="col-12">
                                                    <select value={student || ''} className="form-select" onChange={ (e) => {handleStudentChange(e.target.value)}}>
                                                        {
                                                            userDetails?.user?.userChildren?.map((child, index) => (
                                                                studentsOptions.find(s => s._id === child._id)
                                                                ?
                                                                    <option key={child._id} className={child._id} value={child._id}>{child.name}</option>
                                                                :
                                                                    <Fragment key={index}></Fragment>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    <div>
                                        {
                                            attributesOptions[attributeValue]?.value.date === 'daily'
                                            ?
                                                <div className="row mt-5">
                                                    <label className="form-label fw-bold mb-2 mt-3">Seleccione la/s fecha/s que se aplicarán</label>
                                                    {dates.map((date, key) => (
                                                        <input className='form-control my-3' max={`${new Date().getFullYear() + 1}-06-30`} min={`${new Date().getFullYear()}-${((new Date().getMonth() !== 6 && new Date().getMonth() !== 7 ? new Date().getMonth() : 8) + 1).toLocaleString("es-ES", {minimumIntegerDigits: 2, useGrouping: false})}-${new Date().getDate()}`} key={key} type="date" value={date} onChange={(e) => {calculateDates(dates.map((oldDate, index) => (index === key ? e.target.value : oldDate)))}}/>
                                                    ))}
                                                </div>
                                            :
                                                <></>
                                        }
                                        {
                                            attributesOptions[attributeValue]?.value.date === 'weekly'
                                            ?
                                                <div className="row mt-5">
                                                    <label className="form-label fw-bold mb-2 mt-3">Seleccione el/los mes/es y su respectiva semana que se aplicarán</label>
                                                    {dates.map((date, key) => (
                                                        <Fragment key={key}>
                                                            <div className='col-6 my-3' key={key}>
                                                                <select className='form-select' value={date.month} onChange={(e) => {handleMonthChange(e.target.value, key)}}>
                                                                    {/* Closest months */}
                                                                    {AVAILABLE_MONTHS.map((month, key) => (
                                                                        <Fragment key={key}>
                                                                            {
                                                                                key > new Date().getMonth() - 1 && key !== 6 && key !== 7
                                                                                ?
                                                                                    <option value={`${key}/${new Date().getFullYear()}`}>{`${month} - ${new Date().getFullYear()}`}</option>
                                                                                :
                                                                                    <></>
                                                                            }
                                                                        </Fragment>
                                                                    ))}
                                                                    {/* Next months until June */}
                                                                    {AVAILABLE_MONTHS.map((month, key) => (
                                                                        <Fragment key={key}>
                                                                            {
                                                                                key < 6 && key !== 6 && key !== 7
                                                                                ?
                                                                                    <option value={`${key}/${new Date().getFullYear()} + 1`}>{`${month} - ${new Date().getFullYear() + 1}`}</option>
                                                                                :
                                                                                    <></>
                                                                            }
                                                                        </Fragment>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className='col-6 my-3'>
                                                                <select className='form-select' value={date.week} onChange={(e) => {handleWeekChange(e.target.value, key)}}>
                                                                    {
                                                                        date.month === `${new Date().getMonth()}/${new Date().getFullYear()}`
                                                                        ?
                                                                            date.month ? getMonthWeeks(date.month).map((week, key) => (
                                                                                week >= getMonthWeek(new Date())
                                                                                ?
                                                                                    <option key={key} value={week}>Semana {week}</option>
                                                                                :
                                                                                    <Fragment key={key}></Fragment>
                                                                            )) : <></>
                                                                        :
                                                                            date.month ? getMonthWeeks(date.month).map((week, key) => (
                                                                                <option key={key} value={week}>Semana {week}</option>
                                                                            )) : <></>
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            :
                                                <></>
                                        }
                                        {
                                            attributesOptions[attributeValue]?.value.date === 'monthly'
                                            ?
                                                <div className="row mt-5">
                                                    <label className="form-label fw-bold mb-2 mt-3">Seleccione el/los mes/es que se aplicarán</label>
                                                    {dates.map((date, key) => (
                                                        <select className='form-select my-3' key={key} value={date} onChange={(e) => {calculateDates(dates.map((oldDate, ind) => (ind === key ? e.target.value : oldDate)))}}>
                                                            {/* Closest months */}
                                                            {AVAILABLE_MONTHS.map((month, key) => (
                                                                <Fragment key={key}>
                                                                    {
                                                                        key > new Date().getMonth() - 1 && key !== 6 && key !== 7
                                                                        ?
                                                                            <option value={`${key}/${new Date().getFullYear()}`}>{`${month} - ${new Date().getFullYear()}`}</option>
                                                                        :
                                                                            <></>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                            {/* Next months until June */}
                                                            {AVAILABLE_MONTHS.map((month, key) => (
                                                                <Fragment key={key}>
                                                                    {
                                                                        key < 6 && key !== 6 && key !== 7
                                                                        ?
                                                                            <option value={`${key}/${new Date().getFullYear() + 1}`}>{`${month} - ${new Date().getFullYear() + 1}`}</option>
                                                                        :
                                                                            <></>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </select>
                                                    ))}
                                                </div>
                                            :
                                                <></>
                                        }
                                    </div>
                                    <div>
                                        {
                                        product?.countInStock ? product.countInStock > 0 && product?.catalogMode !== true ? (
                                            <>                                    
                                                <div className="row mt-5">
                                                    <label className="form-label fw-bold mb-2 mt-3">Cantidad</label>
                                                    <div className="col-4">
                                                        <input type="number" className="form-control" value={qty} max={product.countInStock > product.maxUnits ? product.maxUnits : product.countInStock} onChange={ e => setQuantity(e.target.value, product.countInStock > product.maxUnits ? product.maxUnits : product.countInStock)}/>
                                                    </div>
                                                    <div className="col-8">
                                                        <button onClick={userDetails?.user?.userChildren?.length !== 0 && student ? addToCartHandler : () => {}} className={`btn btn-primary btn-block ${userDetails?.user?.userChildren?.length === 0 || student === null ? 'disabled' : ''}`}>Añadir al carrito</button>
                                                    </div>
                                                </div>
                                            </>
                                        ):
                                        <></>
                                        :
                                        product?.attributes[attributeValue || 0]?.stock > 0 && product?.catalogMode !== true ? (
                                            <>                                    
                                                <div className="row mt-5">
                                                    <label className="form-label fw-bold mb-2 mt-3">Cantidad</label>
                                                    <div className="col-4">
                                                        <input type="number" className="form-control" value={qty} max={product?.attributes[attributeValue || 0]?.maxUnits || product?.attributes[attributeValue || 0]?.stock} onChange={ e => setQuantity(e.target.value, product?.attributes[attributeValue || 0]?.maxUnits || product?.attributes[attributeValue || 0]?.stock)}/>
                                                    </div>
                                                    <div className="col-8">
                                                        <button onClick={userDetails?.user?.userChildren?.length !== 0 && student ? addToCartHandler : () => {}} className={`btn btn-primary btn-block ${userDetails?.user?.userChildren?.length === 0 || student === null ? 'disabled' : ''}`}>Añadir al carrito</button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                 }
            </div>
        </div>
        </> 
      )
}