import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteExtracurricularActivity, listExtracurricularActivities } from '../actions/extracurricularActivityActions';
import { EXTRACURRICULARACTIVITY_CREATE_RESET, EXTRACURRICULARACTIVITY_DELETE_RESET } from '../constants/extracurricularActivityConstants';
import { useNavigate } from 'react-router';
import { ToolBar } from '../components/ToolBar';
import { LoadingBox } from '../components/LoadingBox';
import { AlertMessage } from '../components/AlertMessage';
import Swal from 'sweetalert2';
import { CustomDataTable } from '../components/CustomDataTable';
import { Link } from 'react-router-dom';
import { getNotificationsUser } from '../actions/notificationActions';
import removeAccents from '../utils/removeAccents';

export const ExtracurricularActivityListScreen = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const extracurricularActivityList = useSelector((state) => state.extracurricularActivityList);
	const {loading, error, extracurricularActivities} = extracurricularActivityList;  

	const extracurricularActivityCreate = useSelector((state) => state.extracurricularActivityCreate);
	const {loading: loadingCreate, error: errorCreate, success: successCreate, extracurricularActivity: createdExtracurricularActivity } = extracurricularActivityCreate;

	const extracurricularActivityDelete = useSelector((state) => state.extracurricularActivityDelete);
	const { loading: loadingDelete, error: errorDelete, success: successDelete } = extracurricularActivityDelete;

	const { userInfo } = useSelector((state) => state.userSignin);


	let filteredExtracurricularActivities = extracurricularActivities;

	const columns = [
		{
			name: "Referencia",
			selector: (row) => removeAccents(row.sku),
			sortable: false,
			left: true,
			cell: (row) => <span>{row.sku || ''}</span>,
		},
		{
			name: 'Producto de suscripción',
			cell: row => 
			<div className="d-flex align-items-center">                            
				<a href={`/extracurricularactivity/${row._id}`} className="symbol symbol-50px">                                      
						<span className="symbol-label" style={{ backgroundImage: `url(${row.image|| "logo512.png"})` }}></span>
				</a>                            
				<div className="ms-5">                                
						<a href={`/extracurricularactivity/${row._id}`} className="text-gray-800 text-hover-primary fs-5 fw-bolder" data-kt-ecommerce-extracurricularactivity-filter="extracurricularActivity_name">{row.name}</a>
				</div>
			</div>,  
			selector: (row) => removeAccents(row.name),
			sortable: true,
			grow: 2,
		},
		{
			name: "Categoría",
			cell: row =>
			<div className="d-flex align-items-center flex-column">
					{row.category.map((extracurricularActivityCategory, index) => (
							<span key={index}>{extracurricularActivityCategory.name}</span>
					))}
			</div>,
			selector: (row) => row.category,
			sortable: true,
			right: true
		},
		{
			name: "Cantidad",
			selector: (row) => row.countInStock,
			sortable: true,
			right: true,
			cell: (row) => <span>{row.countInStock}</span>,

		},
		{
			name: "Estado",
			cell: row => row.active ? (<div className="rounded-circle bg-success w-15px h-15px" id="kt_ecommerce_add_extracurricularActivity_status"></div>) : <div className="rounded-circle bg-danger w-15px h-15px" id="kt_ecommerce_add_extracurricularActivity_status"></div>,      
			sortable: true,
			right: true,
			selector: (row) => row.active ? row.active : '',
		},  
		{
			name: "Precio",
			selector: (row) => row.price,
			sortable: true,
			right: true,    
			cell: (row) => <span>{row.price}</span>,    
		},
		{
			name: "",
			cell: row => 
			<>
			{
				userInfo.isAdmin
				?
					<div className="row">
						<div className="col-12">
							<i className="bi bi-pencil px-2" onClick={ () => navigate(`/extracurricularactivity/${row._id}/edit`)} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>
							<i className="bi bi-trash-fill px-2" onClick={ () => deleteHandler(row)} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>
						</div>
					</div>
				:
					<div className="row">
						<div className="col-12">
						<i className="bi bi-eye-fill px-2" onClick={() => { navigate(`/extracurricularactivity/${row._id}/edit`); }} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>                
						</div>
					</div>
			}

			</>,
				// <div className="dropdown dropup">
				//     <button className="btn btn-sm btn-light btn-active-light-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
				//         Acciones
				//         <span className="svg-icon svg-icon-5 m-0">
				//             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				//                 <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
				//             </svg>
				//         </span> 
				//     </button>
				//     <ul className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" aria-labelledby="dropdownMenuButton1">
				//         <li className="menu-item px-3"><button className="dropdown-item menu-link px-3" onClick={ () => navigate(`/extracurricularactivity/${row._id}/edit`)} >Editar</button></li>
				//         <li className="menu-item px-3"><button className="dropdown-item menu-link px-3" onClick={ () => deleteHandler(row)} >Eliminar</button></li>
				//     </ul>
				// </div>,
			center: true, 
			sortable: false       
		}
	];

	const deleteHandler = (extracurricularActivity) => {
		Swal.fire({
			title: `¿Desea eliminar el actividad extraescolar ${extracurricularActivity.name} ?`,
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Si',
			denyButtonText: 'No', 
			cancelButtonText: "Cancelar",
			icon: "warning",           
		}).then((result) => {
			if (result.isConfirmed) {
					dispatch(deleteExtracurricularActivity(extracurricularActivity._id));
			}
		})
	}

	useEffect(() => {
		if(successCreate) {
			dispatch({ type: EXTRACURRICULARACTIVITY_CREATE_RESET});
			navigate(`/extracurricularActivitylist`)
		}
		if (successDelete) {
			dispatch({ type: EXTRACURRICULARACTIVITY_DELETE_RESET });
			Swal.fire('Producto de suscripción eliminado', '', 'success')
		}
		if (errorDelete) {
			dispatch({ type: EXTRACURRICULARACTIVITY_DELETE_RESET });
			Swal.fire(errorDelete, '', 'error')
		}
		dispatch(listExtracurricularActivities());
		dispatch(getNotificationsUser());
	}, [dispatch, createdExtracurricularActivity, navigate, successCreate, successDelete, errorDelete])

	return (
		<>
			<ToolBar titulo={"Subscripciones"} hitos={[
				{titulo: "Inicio", enlace: "/" },
				{titulo: "Subscripciones", enlace: "#" },
			]}/>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
				<div className="content flex-row-fluid" id="kt_content">    
					<div>
					{loadingDelete && (<LoadingBox variant={"primary"}/>)}
					{errorDelete && (<AlertMessage variant={"danger"} message={errorDelete}/>)}
					{loadingCreate && (<LoadingBox variant={"primary"}/>)}
					{errorCreate && (<AlertMessage variant={"danger"} message={errorCreate}/>)}
					{loading ? (<LoadingBox variant={"primary"}/>) : 
					error ? (<AlertMessage variant={"danger"} message={error}/>) : (            
						<div className="card card-flush">            
							<div className="card-header align-items-center py-5 gap-2 gap-md-5">        
								<div className="card-toolbar flex-row-fluid justify-content-end gap-5">                
										<Link to='/extracurricularactivity/new' className="btn btn-primary">Crear actividad extraescolar</Link>            
								</div>
							</div>
							<div className="card-body pt-0">
								<CustomDataTable data={filteredExtracurricularActivities} columns={columns}/>                       
							</div>    
						</div>
					)
					}
					</div>
				</div>
			</div>
		</>  
	)
}
